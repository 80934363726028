import React from 'react';
import { graphql } from 'gatsby';
import LayoutMain from 'layouts/layout-main';
import Hero from 'components/shared/hero';
import HtmlContent from 'components/shared/html-content';

export default function(props) {
  const { data } = props;
  const { html } = data.allMarkdownRemark.edges[0].node;
  const { fluid } = data.file.childImageSharp;

  return (
    <LayoutMain>
      <Hero title="Privacy Policy" bgImg={fluid} />
      <div className="container">
        <div className="columns">
          <div className="column is-12 is-10-desktop is-offset-1-desktop">
            <HtmlContent content={html} />
          </div>
        </div>
      </div>
    </LayoutMain>
  );
}

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/privacy-policy/" } }
    ) {
      edges {
        node {
          html
        }
      }
    }
    file(name: { eq: "night-hero-img" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
